@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	min-height: 100vh;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
	overflow: hidden;
}

html {
	height: -webkit-fill-available;
	overflow: hidden;
}

body {
	background-color: #f4f4f4;
	/* background-image: radial-gradient(
    circle 369px at -2.9% 12.9%,
    rgba(247, 234, 163, 1) 0%,
    rgba(236, 180, 238, 0.56) 46.4%,
    rgba(163, 203, 247, 1) 100.7%
  ); */
	color: #4f4f4f;
	margin: 0;
	font-family: "Noto Sans Thai", sans-serif;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: "Noto Sans Thai", sans-serif;
	font-weight: 500;
	color: #4f4f4f;
}

.content {
	@apply max-w-5xl ml-auto mr-auto px-4 md:px-8;
}

.card {
	@apply rounded-xl p-6 shadow-md bg-white;
}

select {
	@apply w-full appearance-none py-2 px-3 focus:outline-none rounded-md bg-gray-100;
}

.select-container {
	@apply relative;
}

.select-container:after {
	@apply content-[''] w-0 h-0 absolute pointer-events-none;
}

.select-container:after {
	@apply opacity-50 top-4 right-3;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 6px solid black;
}

select::-ms-expand {
	@apply hidden;
}

::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-thumb {
	border-radius: 100vh;
	border: 5px solid #c7c7c7;
}

.btn-light {
	@apply shadow-md inline-flex justify-center rounded-lg border border-transparent px-4 py-2 text-sm sm:text-base focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-gray-100 hover:bg-gray-200 disabled:opacity-50 transition duration-300;
}

.btn-secondary {
	@apply shadow-md inline-flex justify-center rounded-lg border border-transparent px-4 py-2 text-sm sm:text-base focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-black hover:bg-gray-900 text-white border-gray-500 disabled:opacity-50 transition duration-300;
}

.btn-primary {
	@apply shadow-md inline-flex justify-center rounded-lg border border-transparent px-4 py-2 text-sm sm:text-base focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 text-gray-700 bg-yellow-400 hover:bg-yellow-500 disabled:opacity-50 transition duration-300;
}

.btn-danger {
	@apply shadow-md inline-flex justify-center rounded-lg border border-transparent px-4 py-2 text-sm sm:text-base focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 transition duration-300;
}

.input {
	@apply w-full py-2 px-3 border-zinc-700 bg-gray-100 rounded-md focus:outline-none focus:ring-amber-500 focus:border-amber-500 focus:shadow-around-input focus:shadow-amber-500/50;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
	@apply bg-yellow-500 border-yellow-500 bg-cover bg-no-repeat;
	background-position: 50%;
}

[type="radio"] {
	@apply appearance-none inline-block w-5 h-5 p-0 rounded-full bg-white border border-gray-700 text-current hover:shadow-md shadow-black align-middle select-none;
	-webkit-print-color-adjust: exact;
}

[type="checkbox"] {
	@apply appearance-none inline-block w-5 h-5 p-0 rounded-md bg-white border border-gray-700 text-current hover:shadow-md shadow-black align-middle select-none disabled:bg-gray-300 disabled:border-gray-500 disabled:shadow-none;
	-webkit-print-color-adjust: exact;
}

[type="checkbox"]:checked {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
}

[type="radio"]:checked {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
}

.loader {
	@apply w-12 h-12 md:w-14 md:h-14 mx-auto relative;
}

.loader:before {
	@apply w-12 h-1 md:w-14 md:h-2 bg-[rgba(255,255,255,0.314)] absolute top-16 md:top-20 left-0 rounded-[50%];
	content: '';
	animation: shadow324 0.5s linear infinite;
}

.loader:after {
	@apply w-full h-full bg-white absolute top-0 left-0 rounded-md;
	content: '';
	animation: jump7456 0.5s linear infinite;
}

@keyframes jump7456 {
	15% {
		border-bottom-right-radius: 3px;
	}

	25% {
		transform: translateY(9px) rotate(22.5deg);
	}

	50% {
		transform: translateY(18px) scale(1, 0.9) rotate(45deg);
		border-bottom-right-radius: 40px;
	}

	75% {
		transform: translateY(9px) rotate(67.5deg);
	}

	100% {
		transform: translateY(0) rotate(90deg);
	}
}

@keyframes shadow324 {
	0%,
	100% {
		transform: scale(1, 1);
	}

	50% {
		transform: scale(1.2, 1);
	}
}
